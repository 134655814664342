<template>
  <div class="news__details main__wrapper text-formatting">
    <div class="block__back" @click="goBack">
      <svg-icon class="icon-arrow-back" name="arrow-back" />
      <h2 class="title-h5 router-link-black">{{ $t(`modals.back`) }}</h2>
    </div>
    <div class="news__details_container" v-if="!!selectedNews">
      <h1 class="news__details_title title-h2">
        {{ selectedNews.name }}
      </h1>
      <div class="tags">
        <div class="tag" v-for="tag in selectedNews.tags" :key="tag">
          {{ tag }}
        </div>
        <h5 class="subtitle">{{ getCurrentTimeZoneDate }}</h5>
      </div>
      <img :src="selectedNews.file" alt="news" class="news__details_img" />
      <div class="news__details_text" v-html="selectedNews.text" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { convertDateToCurrentTimeZone } from '@/library/time';

export default {
  computed: {
    ...mapGetters(['getNewsData']),
    selectedNews() {
      return this.getNewsData?.find((el) => el.uuid === this.$route.params.id);
    },
    getCurrentTimeZoneDate() {
      return convertDateToCurrentTimeZone(this.selectedNews.created_at).date;
    },
  },
  async created() {
    await this.$store.dispatch('getNewsTableData');
  },

  methods: {
    goBack() {
      // this.$router.push({ name: 'news-all' });
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="sass" scoped>

.news__details_title
  padding-bottom: 50px
  font-size: 64px
  line-height: 74px

.news__details_img
  width: 100%
  // max-width: 50vw
  padding-top: 24px

.news__details_text
  width: 80%
  margin: 0 auto
  line-height: 40px
  padding-top: 40px

.news__details_paragraph
  padding-top: 40px

.tags
  display: flex
  gap: 24px
  align-items: center

.tag
  background: black
  color: white
  padding: 10px 16px
</style>
